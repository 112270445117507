import React from 'react';
import './Style.css';

function Plans() {
    return (
        <>
            <p id='plans-head'><span className='Our'>Select</span> a subscription<br />
                that works&nbsp;<span className='Our'>for you</span></p>

            <div id='liness' class="line-h is-grey"></div><br/>

            <div class="grid-2-col is-plans">
                <div class="plans-card">
                    <div class="flex-v-st is-gap-md">
                        <div class="flex-h-sbt">
                            <div class="overflow">
                                <h3 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1', transformStyle: 'preserve-3d' }}>Standard</h3>
                            </div>
                            <div class="overflow">
                                <h3 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1', transformStyle: 'preserve-3d' }}>$955/m</h3>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '1px' }} class="line-h">
                        </div>
                    </div>
                    <div class="grid-2-col is-plans">
                        <div class="overflow is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- 1 Request at a time</div>
                        </div>
                        <div class="overflow is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Trello board with unlimited users</div>
                        </div>
                        <div class="overflow is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Average 48h turnaround</div>
                        </div>
                        <div class="overflow is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Daily Slack updates</div>
                        </div>
                        <div class="overflow is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Pause or cancel anytime</div>
                        </div>
                        <div class="overflow is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Available 5 days a week</div>
                        </div>
                        <div class="overflow is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular text-color-secondary text-style-strikethrough">- Priority support</div>
                        </div>
                        <div class="overflow is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular text-color-secondary text-style-strikethrough">- Goodnight wishes</div>
                        </div>
                        <img id='liness' src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665c5c62b22fc7e9caa3c07f_Frame%20107.svg" loading="lazy" alt="" class="dash-line" />
                        {/* <div style={{justifySelf: 'start'}} class="position">
                            <div class="text-size-regular text-weight-medium">+ Webflow development</div>
                            <img src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665c5da26b6c17ac541dc787_Frame%20109.svg" loading="lazy" alt="Webflow logo icon blue svg" class="webflow-badge" />
                        </div> */}
                        <div id='liness' class="plans-btn-wrap">
                            <a href="mailto:info@qr-ows.com" target="_blank" rel="noreferrer" class="btn-secondary is-white is-no-arrow hide w-inline-block">
                                <div class="btn-inner">
                                    <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Book intro call</div>
                                    <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Book intro call</div>
                                </div>
                            </a>
                            <a href="mailto:info@qr-ows.com" target="_blank" rel="noreferrer" class="btn-main w-inline-block">
                                <div class="btn-inner">
                                    <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Book intro call</div>
                                    <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Book intro call</div>
                                </div>
                                <div class="btn-bg" style={{ transform: 'translate3d(0px, 105%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}></div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="plans-card is-purple">
                    <div class="flex-v-st is-gap-md">
                        <div class="flex-h-sbt">
                            <div class="overflow-hidden">
                                <h3 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1', transformStyle: 'preserve-3d' }}>Plus</h3>
                            </div>
                            <div class="overflow-hidden">
                                <h3 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1', transformStyle: 'preserve-3d' }}>$1500/m</h3>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '1px' }} class="line-h is-white">
                        </div>
                    </div>
                    <div class="grid-2-col is-plans">
                        <div class="overflow-hidden is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular text-weight-medium">- 2 Request at a time</div>
                        </div>
                        <div class="overflow-hidden is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Trello board with unlimited users</div>
                        </div>
                        <div class="overflow-hidden is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular text-weight-medium">- Average 24-48h turnaround</div>
                        </div>
                        <div class="overflow-hidden is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Daily Slack updates</div>
                        </div>
                        <div class="overflow-hidden is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Pause or cancel anytime</div>
                        </div>
                        <div class="overflow-hidden is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Available 5 days a week</div>
                        </div>
                        <div class="overflow-hidden is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Priority support</div>
                        </div>
                        <div class="overflow-hidden is-animated" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                            <div class="text-size-regular">- Goodnight wishes</div>
                        </div>
                        <img id='liness' src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665c5c62b22fc7e9caa3c07f_Frame%20107.svg" loading="lazy" alt="" class="dash-line" />
                        {/* <div style={{justifySelf: 'start'}} class="position">
                            <div class="text-size-regular text-weight-medium">+ Webflow development</div>
                            <img src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665c5da591da51a9e9b7d39e_Frame%20119.svg" loading="lazy" alt="Webflow logo icon white svg" class="webflow-badge" />
                        </div> */}
                        <div id='liness' class="plans-btn-wrap">
                            <a href="mailto:info@qr-ows.com" target="_blank" rel="noreferrer" class="btn-secondary is-white is-no-arrow hide w-inline-block">
                                <div class="btn-inner">
                                    <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Book intro call</div>
                                    <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Book intro call</div>
                                </div>
                            </a>
                            <a href="mailto:info@qr-ows.com" target="_blank" rel="noreferrer" class="btn-main is-white w-inline-block">
                                <div class="btn-inner">
                                    <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Book intro call</div>
                                    <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Book intro call</div>
                                </div>
                                <div class="btn-bg is-white" style={{ transform: 'translate3d(0px, 105%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Plans
