import React, { useState, useRef, useEffect } from 'react';

const Faq = () => {
    const [openDropdowns, setOpenDropdowns] = useState({}); // Object to track the open state of each dropdown
    const answerRefs = useRef({}); // Store refs for each FAQ item

    const toggleDropdown = (id) => {
        setOpenDropdowns((prevState) => ({
            ...prevState,
            [id]: !prevState[id], // Toggle the open state for the specific dropdown by its ID
        }));
    };

    useEffect(() => {
        // Dynamically update the max-height for each FAQ based on its open state
        Object.keys(answerRefs.current).forEach((id) => {
            if (openDropdowns[id]) {
                answerRefs.current[id].style.maxHeight = `${answerRefs.current[id].scrollHeight}px`;
            } else {
                answerRefs.current[id].style.maxHeight = '0px';
            }
        });
    }, [openDropdowns]);

    const faqs = [
        {
            id: 'faq1',
            question: 'How is subscription better than a regular agency or full-time designer?',
            answer: 'With a subscription, you get the best of both worlds. Unlike a regular agency...',
        },
        {
            id: 'faq2',
            question: 'What services are included in the subscription?',
            answer: 'You get unlimited design requests, fast turnaround times, and...',
        },
        {
            id: 'faq3',
            question: 'How long does it take to complete a design?',
            answer: 'Most designs are completed within 1-2 business days, depending on...',
        },
    ];

    return (
        <>
            <div className='glb-padd'>
                <div className='cont-lrg'>
                    <div className='faq_wrap'>
                        <div className='heading-wrap'>
                            <h2 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1', color: '#2a2a2a' }}>FAQs</h2>
                        </div>
                        <div className='faq_list'>
                            {faqs.map((faq) => (
                                <div className="faq_dropdown" key={faq.id}>
                                    {/* The clickable area for toggling the dropdown */}
                                    <div className="flex-h-sbc is-gap-lg" onClick={() => toggleDropdown(faq.id)}>
                                        <div className="overflow">
                                            <h4>{faq.question}</h4>
                                        </div>
                                        {/* Dropdown toggle icon (plus or minus, can animate rotation) */}
                                        <div
                                            className="faq_icon"
                                            style={{
                                                transform: openDropdowns[faq.id] ? 'rotate(45deg)' : 'rotate(0deg)', // Rotate icon when open
                                                transition: 'transform 0.3s ease', // Smooth rotation
                                            }}
                                        >
                                            <img
                                                src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665d87ab984bffad59b0bc6e_Group%201000002264.svg"
                                                alt="Toggle"
                                                className="image-100"
                                            />
                                        </div>
                                    </div>

                                    {/* The hidden FAQ answer content */}
                                    <div
                                        ref={(el) => (answerRefs.current[faq.id] = el)} // Store each FAQ's ref by its id
                                        className="faq_answer"
                                        style={{
                                            maxHeight: '0px', // Start collapsed
                                            overflow: 'hidden', // Hide content when collapsed
                                            transition: 'max-height 0.5s ease', // Smooth transition for opening and closing
                                        }}
                                    >
                                        <div className="faq_answer-inner">
                                            <div className="rich-text w-richtext">
                                                <p>{faq.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line-h-ab is-animated" style={{ width: '100%', height: '1px' }}></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Faq;
