import React from 'react';
import './Style.css';
import cd1 from './Assets/a1.png';
import cd2 from './Assets/a2.png';
import cd3 from './Assets/a3.png';

function About() {
  return (
    <>
      <p id='cadH'>Here’s how you get it</p>
      <div className='container'>
        <article className='card' style={{backgroundColor: '#c78458'}}>
          <div className='card-content'>
          <p style={{fontSize: '20px'}}>Begin immediately!</p>
            <img className='abt-img' src={cd1} alt="img1" />
            <p>Choose a plan and schedule a complimentary discovery call to find out how we can collaborate. If everything is in order, we will start on the same day.</p>
          </div>
        </article>
        <article className='card' style={{backgroundColor: '#fde7a2'}}>
          <div className='card-content'>
          <p style={{fontSize: '20px'}}>Ask for patterns</p>
            <img className='abt-img' src={cd2} alt="img2" />          
            <p>Feel free to ask for as many designs as you like and relax while we create your designs and provide initial drafts within 48 hours.</p>
          </div>
        </article>
        <article className='card' style={{backgroundColor: '#b49068'}}>
          <div className='card-content'>
            <p style={{fontSize: '20px'}}>Complete; make final.</p>
            <img className='abt-img' src={cd3} alt="img3" />
            <p>Despite our efforts to achieve the goal in one try, feel free to request multiple iterations if necessary. There are no extra expenses.</p>
          </div>
        </article>
      </div>
      <p id='cadF'>At QR-OWS, we exceed expectations to guarantee your satisfaction at each stage of the procedures, as our subscription plan is tailored to meet your needs.</p>
    </>
  );
}

export default About;
