import React, { useState, useEffect } from 'react';
import './Style.css';
import sp1 from './Assets/ani.png';
import sp2 from './Assets/ane.png';

function Animate() {
    const [scrollY, setScrollY] = useState(0);
    const maxScroll = 2000; // Define maxScroll

    // Calculate the stop position based on the desired point below the glass-wrap
    const stopScrollY = 1500; // Adjust this value based on where you want the images to stop

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // Set scrollY to currentScrollY, but stop at stopScrollY
            if (currentScrollY <= stopScrollY) {
                setScrollY(currentScrollY);
            } else {
                setScrollY(stopScrollY); // Lock scrollY at stopScrollY
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Calculate scaling and translation values based on scrollY
    const scale = Math.max(0.4, 1 - scrollY / (maxScroll / 2));

    // Translation calculations with limits
    const translateX1 = Math.max(-30, -45 + scrollY / (maxScroll / 1.5) * 42);
    const translateY1 = Math.max(-100, -94 + scrollY / (maxScroll / 1.5) * 90);

    const translateX2 = Math.max(-20, -89 + scrollY / (maxScroll / 1.5) * 78);
    const translateY2 = Math.max(-150, -94 + scrollY / (maxScroll / 1.5) * 90);

    // const translateX1 = Math.max(-20, -30 + (scrollY / maxScroll) * 40); 
    // const translateY1 = Math.max(-10, -40 + (scrollY / maxScroll) * 60); 
    // const translateX2 = Math.min(20, 30 - (scrollY / maxScroll) * 44); 
    // const translateY2 = Math.max(-10, -40 + (scrollY / maxScroll) * 60); 

    return (
        <>
            <div style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}>
                <div style={{ width: '100%', maxWidth: '100rem' }}>
                    <div style={{ height: '250vh', position: 'relative' }}>
                        <div style={{ height: '250vh' }}>
                            <div className='glass-wrap' style={{ zIndex: 2, position: 'relative' }}>
                                <div className="glass-block">
                                    <div className="wrap-desktop">
                                        <div style={{ position: 'relative', overflow: 'hidden' }}>
                                            <div className="intro_text">
                                            Create in a more effective manner
                                            </div>
                                        </div>
                                        <div style={{ position: 'relative', overflow: 'hidden' }}>
                                            <div className="intro_text">
                                            in a more straightforward manner.                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-wrap mobile">
                                        <div style={{ position: 'relative', overflow: 'hidden' }}>
                                            <div className="intro_text">
                                            Create in a more effective manner
                                            </div>
                                        </div>
                                        <div style={{ position: 'relative', overflow: 'hidden' }}>
                                            <div className="intro_text">
                                            in a more.
                                            </div>
                                        </div>
                                        <div style={{ position: 'relative', overflow: 'hidden' }}>
                                            <div className="intro_text">
                                            straightforward manner.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="animation-wrap">
                                <div className="sticky-wrap">
                                    <img
                                        src={sp1}
                                        loading="eager"
                                        alt="sl"
                                        className="block purple"
                                        style={{
                                            willChange: 'transform',
                                            transform: `translate3d(${translateX1}vw, ${translateY1}vh, 0px) scale3d(${scale}, ${scale}, 1) rotate(12deg)`,
                                            zIndex: 1
                                        }}
                                    />
                                    <img
                                        src={sp2}
                                        loading="eager"
                                        alt=""
                                        className="block yellow"
                                        style={{
                                            willChange: 'transform',
                                            transform: `translate3d(${translateX2}vw, ${translateY2}vh, 0px) scale3d(${scale}, ${scale}, 1) rotate(-18deg)`,
                                            zIndex: 1
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Animate;
