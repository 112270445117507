import React from 'react';
import './Style.css';
import wave from './Assets/wave.png';
import wavebt from './Assets/wavebt.png';

function Gain() {
  return (
    <>
      <div className='wave-top'>
        <div class="wave_wrap">
          <img src={wave}  alt="wave" class="wave_image" />
          <img src={wave}  alt="wave" class="wave_image" />
        </div>
      </div>

      <div className='benifit-bg' style={{ backgroundColor: '#2b1511' }}>

        <p id='benifit-head'><span className='Our'>So,&nbsp;</span>why work<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;with QR OWS?</p>
        <div className='grid benifit'>
          <div className='flex gap'>
            <div className='flex-h'>
              <div className='overflow'>
                <h3 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }}>Quickness
                </h3>
              </div>
              <div style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }} class="icon-wrap">
                <img src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665c4c21ba40744cc3dd7b44_mdi_globe.svg" loading="lazy" alt="Lightning icon svg" class="icon-md" />
                <div class="icon yellow" >
                </div>
              </div>
            </div>
            <div className='cl-hd'>
              <div style={{ widow: '100%', height: '1px' }} class="line-h white">
                <div class="overflow faq" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                  <p class="text-height">Get your designs promptly without sacrificing quality. Our model allows us to dedicate 98% of our time to actually working, without the need for long meetings and numerous emails.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex gap'>
            <div className='flex-h'>
              <div className='overflow'>
                <h3 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }}>Clarity
                </h3>
              </div>
              <div style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }} class="icon-wrap">
                <img src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665c4c21ba40744cc3dd7b44_mdi_globe.svg" loading="lazy" alt="Lightning icon svg" class="icon-md" />
                <div class="icon yellow" >
                </div>
              </div>
            </div>
            <div className='cl-hd'>
              <div style={{ widow: '100%', height: '1px' }} class="line-h white">
                <div class="overflow faq" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                  <p class="text-height">Bid farewell to budget unpredictability with our clear and set pricing structure. You can stop or cancel whenever you want.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex gap'>
            <div className='flex-h'>
              <div className='overflow'>
                <h3 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }}>Findings
                </h3>
              </div>
              <div style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }} class="icon-wrap">
                <img src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665c4c21ba40744cc3dd7b44_mdi_globe.svg" loading="lazy" alt="Lightning icon svg" class="icon-md" />
                <div class="icon yellow" >
                </div>
              </div>
            </div>
            <div className='cl-hd'>
              <div style={{ widow: '100%', height: '1px' }} class="line-h white">
                <div class="overflow faq" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                  <p class="text-height">Effective design is characterized by its ability to perform successfully and make a lasting impact on your audience. Therefore, we merge the realms of performance and aesthetics.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex gap'>
            <div className='flex-h'>
              <div className='overflow'>
                <h3 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }}>Involvement
                </h3>
              </div>
              <div style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }} class="icon-wrap">
                <img src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665c4c21ba40744cc3dd7b44_mdi_globe.svg" loading="lazy" alt="Lightning icon svg" class="icon-md" />
                <div class="icon yellow" >
                </div>
              </div>
            </div>
            <div className='cl-hd'>
              <div style={{ widow: '100%', height: '1px' }} class="line-h white">
                <div class="overflow faq" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                  <p class="text-height">Despite working separately, we will provide daily updates on Slack (if it's okay with you) and schedule calls and looms as necessary.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex gap'>
            <div className='flex-h'>
              <div className='overflow'>
                <h3 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }}>Flexibility
                </h3>
              </div>
              <div style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }} class="icon-wrap">
                <img src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665c4c21ba40744cc3dd7b44_mdi_globe.svg" loading="lazy" alt="Lightning icon svg" class="icon-md" />
                <div class="icon yellow" >
                </div>
              </div>
            </div>
            <div className='cl-hd'>
              <div style={{ widow: '100%', height: '1px' }} class="line-h white">
                <div class="overflow faq" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                  <p class="text-height">After registering, you will receive your personal Trello board that is simple to manage, allowing you to add and monitor all your requests. There is no restriction on the number of users, feel free to add as many people as necessary.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex gap'>
            <div className='flex-h'>
              <div className='overflow'>
                <h3 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }}>Ability to expand.
                </h3>
              </div>
              <div style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', opacity: '1', transformStyle: 'preserve-3d' }} class="icon-wrap">
                <img src="https://cdn.prod.website-files.com/66547507d69d70a29b5cb91e/665c4c21ba40744cc3dd7b44_mdi_globe.svg" loading="lazy" alt="Lightning icon svg" class="icon-md" />
                <div class="icon yellow" >
                </div>
              </div>
            </div>
            <div className='cl-hd'>
              <div style={{ widow: '100%', height: '1px' }} class="line-h white">
                <div class="overflow faq" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d', opacity: '1' }}>
                  <p class="text-height">Whether you're one-person business or a large company, our subscription plans scale with your needs.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="wave-top">
        <div class="wave_wrap">
          <img src={wavebt}  alt="wave" class="wave_image" />
          <img src={wavebt}  alt="wave" class="wave_image" />
        </div>
      </div>
    </>
  )
}

export default Gain
