import React from 'react';
import ows from './Assets/qr-logo.png';
import './Style.css';

const Navbar = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className='navbar-wrapper'>
        <div className='glb-padd'>
          <div className='cont-lrg'>
            <div className='navbar'>
              <li onClick={() => scrollToSection('home')} class="logo-wrap is-navbar w-inline-block w--current">
                <img src={ows} alt="logo" class="nav-logo is-white" />
              </li>
              <div class="menu-wrap" style={{ willChange: 'background', backgroundColor: 'rgba(42, 42, 42, 0.05)', opacity: '1' }}>
                <div class="menu">
                  <li onClick={() => scrollToSection('about')} class="nav-btn w-inline-block">
                    <div class="nav-text is-menu" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>About</div>
                    <div class="line-h hide-tablet" style={{ width: '0%', height: '0.5px' }}></div>
                  </li>
                  <li onClick={() => scrollToSection('services')} class="nav-btn w-inline-block">
                    <div class="nav-text is-menu" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Services</div>
                    <div class="line-h hide-tablet" style={{ width: '0%', height: '0.5px' }}></div>
                  </li>
                  <li onClick={() => scrollToSection('works')} class="nav-btn w-inline-block">
                    <div class="nav-text is-menu" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Work</div>
                    <div class="line-h hide-tablet" style={{ width: '0%', height: '0.5px' }}></div>
                  </li>
                  <li onClick={() => scrollToSection('benefits')} class="nav-btn w-inline-block">
                    <div class="nav-text is-menu" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Benefits</div>
                    <div class="line-h hide-tablet" style={{ width: '0%', height: '0.5px' }}></div>
                  </li>
                  <li onClick={() => scrollToSection('faq')} class="nav-btn hide-mobile-portrait w-inline-block">
                    <div class="nav-text is-menu" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>FAQs</div>
                    <div class="line-h hide-tablet" style={{ width: '0%', height: '0.5px' }}></div>
                  </li>
                  <li onClick={() => scrollToSection('contacts')} class="nav-btn w-inline-block">
                    <div class="nav-text is-menu" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Contact</div>
                    <div class="line-h hide-tablet" style={{ width: '0%', height: '0.5px' }}>
                    </div>
                  </li>
                </div>
              </div>

              <li onClick={() => scrollToSection('plans')} class="btn-main is-navbar w-inline-block" style={{ color: 'rgb(253, 253, 253)', willChange: 'background', backgroundColor: 'rgb(42, 42, 42)', opacity: '1', transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>
                <div class="btn-inner">
                  <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>See plans</div>
                  <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>See plans</div>
                </div>
                <div class="btn-bg" style={{ transform: 'translate3d(0px, 105%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}></div>
                <div class="btn-bg" style={{ transform: 'translate3d(0px, 105%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}></div>
              </li>

              <div class="menu-btn" style={{ willChange: 'background', backgroundColor: 'rgba(42, 42, 42, 0.05)' }}>
                <div>Menu</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
