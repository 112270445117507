import React from 'react';
import './Style.css';
import pic4 from './Assets/s3.png';
import pic5 from './Assets/s4.png';

function Services() {
  return (
    <>
       <p id='service-head'><span className='Our'>Our</span> services&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br /> include</p>
        <hr className="custom-hr" />
        <div className='container'>
          <article className='card' id='cad4'>
            <div className='in-ht'>
              <p>Landingpage site</p>
              <img
                src={pic4}
                alt='Web'
                className='web-css'
              /></div>
            <div className='ser-typ'>
              <div className='srvce-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    Landing page
                  </div>
                </div>
              </div>
              <div className='srvce-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    Multipage websites
                  </div>
                </div>
              </div>
              <div className='srvce-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    Blogs
                  </div>
                </div>
              </div>
              <div className='srvce-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    Webflow
                  </div>
                </div>
              </div>
              <div className='srvce-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    Animation & Intraction
                  </div>
                </div>
              </div>
              <div className='srvce-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    CMS
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article className='card' id='cad4'>
            <div className='in-ht'>
              <p>QR Scan site</p>
              <img
                src={pic5}
                alt='Web'
                className='web-css'
              /></div>
            <div className='ser-typ'>
              <div className='srvces-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    Ads
                  </div>
                </div>
              </div>
              <div className='srvces-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    Social media
                  </div>
                </div>
              </div>
              <div className='srvces-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    Posters
                  </div>
                </div>
              </div>
              <div className='srvces-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    Buisness cards
                  </div>
                </div>
              </div>
              <div className='srvces-pill'>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div style={{ fontSize: '1.125rem', lineHeight: '1.3' }}>
                    E-commerce
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>

    </>
  )
}

export default Services
