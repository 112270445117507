import React from 'react';
import './Style.css';
import port1 from './Assets/port1.png';
import port2 from './Assets/port2.png';
import port3 from './Assets/port3.png';
import port4 from './Assets/port4.png';

function Works() {

  return (
    <>
      <p id='portfolio-head'>
        <span className='Our'>Check out</span>
        <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;our portfolio</p>
      <div className="space">
      </div>
      <div className="port-grid">
        <div class="portfolio-column-1" style={{ willChange: 'transform', transform: 'translate3d(0px, -2.76384%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !impo', transformStyle: 'preserve-3d' }}>
          <div class="portfolio_img-wrap is-1">
            <img src={port1} alt="port1" />
          </div>
          <div class="portfolio_img-wrap is-3">
          <img src={port2} alt="port2" />
          </div>
        </div>
        <div class="porfolio-column-2" style={{ willChange: 'transform', transform: 'translate3d(0px, 8.50224%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>
          <div class="portfolio_img-wrap is-2">
          <img src={port3} alt="port3" />
          </div>
          <div class="portfolio_img-wrap is-4">
          <img src={port4} alt="port4" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Works