import React from 'react';
import './Style.css';
import Navbar from './Navbar';
import gif from './Assets/gif1.gif';
import Animate from './Animate';
import Gain from './Gain';
import Plans from './Plans';
import Services from './Services';
import About from './About';
import Works from './Works';
import Contact from './Contact';
import Faq from './Faq';

const Home = () => {


  return (
    <>
      <section id="home" className='sec is-top'>
        <Navbar />

        <div className="row" >
          <div className="column" id='text_id'>
            <h2 className="head">Start scanning<br /><span className='Our'>to grow your</span><br />Business Online</h2>
          </div>
          <div className="column" id='img_id'>
            <img src={gif} alt="animated-gif" style={{ width: '100%', maxWidth: '800px', marginTop: '20px' }} />
          </div>
        </div>

      </section>

      <section id="scrolling-animation">
        <Animate />
      </section>

      <section id="about">
        <About />
      </section><br />

      <section id="services" style={{ height: 'auto' }}>
        <Services />
      </section>

      <section id="works" style={{ height: 'auto' }}>
        <Works />
      </section><br /><br /><br /><br /><br /><br />

      <section id="benefits">
        <Gain />
      </section>

      <section id="plans" style={{ height: 'auto', padding: '30px' }}>
        <Plans />
      </section>

      <section id="faq" style={{ height: 'auto' }}>
        <Faq />
      </section><br /><br /><br />

      <section id="contacts" style={{ height: '100vh' }}>
        <Contact />
      </section>

    </>
  );
};

export default Home;
