import React from 'react';
import './Style.css';
// import { Link } from 'react-scroll';
import { Link } from 'react-router-dom';
import { FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import wave from './Assets/wave.png';

function Contact() {
    return (
        <>
            <div className='wave-top'>
                <div class="wave_wrap">
                    <img src={wave} loading="eager" alt="" class="wave_image" />
                    <img src={wave} loading="lazy" alt="" class="wave_image" />
                </div>
            </div>

            <div className='benifit-bg' style={{ backgroundColor: '#2b1511' }}>
                <div className='glb-padd'>
                    <div className='cont-lrg'>
                        <div className="sec-lrg">
                            <div className="flex-v-ct is-gap-lg">
                                <div className="flex-v-lt">
                                    <div class="heading-wrap hide-mobile-portrait">
                                        <h2 class="heading" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1', transformStyle: 'preserve-3d' }} >Let us take care of</h2>
                                    </div>
                                    <div class="heading-wrap is-footer">
                                        <h2 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1', transformStyle: 'preserve-3d' }}>your design needs</h2>
                                    </div>
                                    <div class="heading-wrap is-mobile">
                                        <h2 style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1', transformStyle: 'preserve-3d' }}>Let us take care of your design needs</h2>
                                    </div>
                                </div>
                                <a href="#plans" class="btn-main is-white w-inline-block">
                                    <div class="btn-inner">
                                        <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Start right now
                                        </div>
                                        <div class="btn-text" style={{ transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>Start right now
                                        </div>
                                    </div>
                                    <div class="btn-bg is-white" style={{ transform: 'translate3d(0px, 105%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d' }}>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div id='liness' class="line-h is-grey"></div>

                        <div className="row" style={{ width: '100%' }}>
                            <div className="column" style={{ width: '50%' }}>

                            </div>
                            <div className="column" style={{ width: '50%' }}>
                                <p style={{ color: '#fff', fontSize: '20px' }}>Have any questions? Reach out!<br />
                                    We reply fast</p>
                                <a href="mailto:info@qr-ows.com" style={{ color: '#fff', fontSize: '25px' }}>info@qr-ows.com</a><br /><br /><br /><br />
                                <div className="row" style={{ width: '100%', gap: '15px' }}>
                                    <div className="column"
                                        id="sm-box"
                                        onClick={() => window.open('https://linkedin.com', '_blank')} >
                                        <div class="icon-md_wrap">
                                            <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                                                <FaLinkedin size={30} color="#0A66C2"/>
                                            </a>
                                        </div><br /><br /><br /><br /><br />
                                        <p><b>LinkedIn</b></p>
                                    </div>
                                    <div className="column"
                                        id="sm-box"
                                        onClick={() => window.open('https://instagram.com', '_blank')} >
                                        <div class="icon-md_wrap">
                                            <a href="https://instagram.com" target="_blank" rel="noreferrer">
                                                <FaInstagram size={30} color="#C13584"/>
                                            </a></div><br /><br /><br /><br /><br />
                                        <p><b>Instagram</b></p>
                                    </div>
                                    <div className="column"
                                        id="sm-box"
                                        onClick={() => window.open('https://tiktok.com', '_blank')} >
                                        <div class="icon-md_wrap">
                                            <a href="https://tiktok.com" target="_blank" rel="noreferrer">
                                                <FaTiktok size={30} color="#000000"/>
                                            </a></div><br /><br /><br /><br /><br />
                                        <p><b>TikTok</b></p>
                                    </div>

                                </div>
                            </div>
                        </div><br />
                        <div className="row" style={{ width: '100%', color: '#fff' }}>
                            <div className="column" style={{ width: '50%' }}>
                                <p>© QR-OWS 2024. All rights reserved.</p>
                            </div>
                            <div className="column" style={{ width: '50%', textAlign: 'right' }}>
                                <Link to='/privacy' style={{ color: '#fff' }}>Privacypolicy</Link>&nbsp;&nbsp;
                                <Link to='/terms' style={{ color: '#fff' }}>Terms&conditions</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
