import Home from './Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Privacy from './Privacy';
import Terms from './Terms'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;